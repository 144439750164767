<template>
  <section>
    <h1 class="text-[2rem]">Honeywell API documentation</h1>
    <div class="border border-gray-900">
      In publishing and graphic design, Lorem ipsum is a placeholder text
      commonly used to demonstrate the visual form of a document or a typeface
      without relying on meaningful content. Lorem ipsum may be used as a
      placeholder before final copy is available. It is also used to temporarily
      replace text in a process called greeking, which allows designers to
      consider the form of a webpage or publicatio
    </div>
  </section>
  <section>
    <h1 class="text-[2rem]">SKU API</h1>
    <div >
        <table class="w-40 text-xs text-left text-gray-500 dark:text-gray-400 border border-gray-800" >
        <thead
          class="text-xs text-gray-700 bg-gray-50 dark:bg-gray-700 dark:text-gray-400 border border-gray-800 "
        >
          <th class ="border border-gray-800 w-40 p-5 text-center" >Attribute</th>
          <th class ="border border-gray-800 w-40 p-5 text-center" >Type</th>
        </thead>
        <tbody>
          <tr
            class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 border border-gray-800 "
          >
            <td class ="border border-gray-800 w-40">TestData 1</td>
            <td class ="border border-gray-800 w-40">TestData 2</td>
          </tr>
        </tbody>
      </table>
    </div>
  </section>

</template>